<template>
  <BaseRow class="mt-6">
    <BaseCol cols="12">
      <BaseHeadline size="2" fake="4">
        {{ $t("rp.reports.headlines.courses") }}
      </BaseHeadline>
    </BaseCol>

    <BaseCol cols="12" md="4">
      <ReportTypeCard :type="reportTypes.COURSES" :days-into-past="daysIntoPast" />
    </BaseCol>

    <BaseCol cols="12" md="4">
      <ReportTypeCard :type="reportTypes.COURSES_SHOWED" :days-into-past="daysIntoPast" />
    </BaseCol>

    <BaseCol cols="12" md="4">
      <ReportTypeCard :type="reportTypes.COURSES_PURCHASED" :days-into-past="daysIntoPast" />
    </BaseCol>
  </BaseRow>

  <BaseRow class="mt-6">
    <BaseCol cols="12">
      <BaseHeadline size="2" fake="4">
        {{ $t("rp.reports.headlines.users") }}
      </BaseHeadline>
    </BaseCol>

    <BaseCol cols="12" md="4">
      <ReportTypeCard :type="reportTypes.USERS" :days-into-past="daysIntoPast" />
    </BaseCol>

    <BaseCol cols="12" md="4">
      <ReportTypeCard :type="reportTypes.COURSE_USER_AVERAGE" :days-into-past="daysIntoPast" />
    </BaseCol>

    <BaseCol cols="12" md="4">
      <ReportTypeCard :type="reportTypes.TEACHERS" :days-into-past="daysIntoPast" />
    </BaseCol>
  </BaseRow>

  <BaseRow class="mt-6">
    <BaseCol cols="12">
      <BaseHeadline size="2" fake="4">
        {{ $t("rp.reports.headlines.courseRequests") }}
      </BaseHeadline>
    </BaseCol>

    <BaseCol cols="12" md="4">
      <ReportTypeCard :type="reportTypes.COURSE_REQUESTS" :days-into-past="daysIntoPast" />
    </BaseCol>

    <BaseCol cols="12" md="4">
      <ReportTypeCard :type="reportTypes.COURSE_REQUESTS_EDITING" :days-into-past="daysIntoPast" />
    </BaseCol>

    <BaseCol cols="12" md="4">
      <ReportTypeCard :type="reportTypes.COURSE_REQUESTS_ONLINE" :days-into-past="daysIntoPast" />
    </BaseCol>

    <BaseCol cols="12" md="4">
      <ReportTypeCard :type="reportTypes.COURSE_REQUESTS_REVIEW" :days-into-past="daysIntoPast" />
    </BaseCol>

    <BaseCol cols="12" md="4">
      <ReportTypeCard
        :type="reportTypes.COURSE_REQUESTS_CHANGES_REQUESTED"
        :days-into-past="daysIntoPast"
      />
    </BaseCol>
  </BaseRow>
</template>

<script>
import ReportTypeCard from "@/modules/reports-rp/views/Reports/partials/ReportTypeCard";
import reportTypes from "@/modules/reports-rp/constants/reportTypes";

export default {
  components: {
    ReportTypeCard,
  },
  props: {
    daysIntoPast: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      reportTypes,
    };
  },
};
</script>
