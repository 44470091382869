<template>
  <BaseDropdown
    v-model="daysIntoPast"
    label="rp.reports.daysIntoPastSelect.label"
    :options="options"
    optionLabel="label"
    optionValue="days"
    floatingLabel
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      options: [
        {
          label: this.$t("rp.reports.daysIntoPastSelect.types.day"),
          days: 1,
        },
        {
          label: this.$t("rp.reports.daysIntoPastSelect.types.week"),
          days: 7,
        },
        {
          label: this.$t("rp.reports.daysIntoPastSelect.types.month"),
          days: 30,
        },
        {
          label: this.$t("rp.reports.daysIntoPastSelect.types.year"),
          days: 365,
        },
      ],
    };
  },
  computed: {
    daysIntoPast: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
