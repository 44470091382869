<template>
  <BaseCard>
    <BaseHeadline size="4" fake="6">
      {{ $t("rp.reports.types." + getReportType()) }}
    </BaseHeadline>

    <ReportChart :types="[type]" :days-into-past="daysIntoPast" />
  </BaseCard>
</template>

<script>
import ReportChart from "./ReportChart";
import { reversedReportTypes } from "../../../constants/reportTypes";

export default {
  components: { ReportChart },
  props: {
    type: {
      type: Number,
      required: true,
    },
    daysIntoPast: {
      type: Number,
      required: false,
      default: 30,
    },
  },
  methods: {
    getReportType() {
      return reversedReportTypes[this.type];
    },
  },
};
</script>
