export const reversedReportTypes = {
  1: "COURSES",
  2: "COURSES_SHOWED",
  3: "COURSES_PURCHASED",
  4: "USERS",
  5: "COURSE_USER_AVERAGE",
  6: "TEACHERS",
  7: "COURSE_REQUESTS",
  8: "COURSE_REQUESTS_EDITING",
  9: "COURSE_REQUESTS_ONLINE",
  10: "COURSE_REQUESTS_REVIEW",
  11: "COURSE_REQUESTS_CHANGES_REQUESTED",
};

export default {
  COURSES: 1,
  COURSES_SHOWED: 2,
  COURSES_PURCHASED: 3,
  USERS: 4,
  COURSE_USER_AVERAGE: 5,
  TEACHERS: 6,
  COURSE_REQUESTS: 7,
  COURSE_REQUESTS_EDITING: 8,
  COURSE_REQUESTS_ONLINE: 9,
  COURSE_REQUESTS_REVIEW: 10,
  COURSE_REQUESTS_CHANGES_REQUESTED: 11,
};
