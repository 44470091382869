<template>
  <div v-if="isLoading" class="text-center py-8">
    <BaseProgressSpinner size="24" />
  </div>
  <HAWChart v-else :data="chartData" :show-legend="false" />
</template>

<script>
import HAWChart from "@/modules/reports-rp/components/HAWChart";
import useApi from "@use/useApi";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import colors from "@/modules/haw-components-hac/constants/colors";
import { reversedReportTypes } from "@/modules/reports-rp/constants/reportTypes";

export default {
  components: { HAWChart },

  props: {
    types: {
      type: Array,
      required: true,
    },

    daysIntoPast: {
      type: Number,
      required: false,
      default: 30,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const chartData = ref(null);

    const { isLoading, docs, callApi } = useApi({
      module: "rp",
      method: "showReportBarChart",
      onMount: true,
      params: {
        types: props.types,
        daysIntoPast: props.daysIntoPast,
      },
      onSuccess: () => {
        const labels = docs.value.labels.map((item) => {
          return dayjs(item).format("DD.MM.YYYY");
        });

        const datasets = docs.value.datasets.map((item) => {
          const type = reversedReportTypes[item.label];

          item.label = t(`rp.reports.types.${type}`);
          item.borderColor = colors.PRIMARY;

          return item;
        });

        chartData.value = {
          labels,
          datasets,
        };
      },
    });

    const reload = () => {
      if (!isLoading.value) {
        callApi({
          types: props.types,
          daysIntoPast: props.daysIntoPast,
        });
      }
    };

    watch(() => props.types, reload);
    watch(() => props.daysIntoPast, reload);

    return {
      isLoading,
      chartData,
    };
  },
};
</script>
