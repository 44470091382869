<template>
  <Chart type="line" v-if="data" :data="data" :options="options" />
</template>

<script>
import Chart from "primevue/chart";
import colors from "@/modules/haw-components-hac/constants/colors";

export default {
  name: "HAWChart",
  components: { Chart },
  props: {
    data: {
      type: undefined,
      required: false,
      default: null,
    },
    showLegend: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      options: {
        plugins: {
          legend: {
            display: this.showLegend,
            labels: {
              color: colors.CHART_LABELS,
            },
          },
        },
        scales: {
          x: {
            display: false,
            ticks: {
              color: colors.CHART_TICKS,
            },
            grid: {
              color: colors.CHART_GRID,
            },
          },
          y: {
            ticks: {
              color: colors.CHART_TICKS,
            },
            grid: {
              color: colors.CHART_GRID,
            },
          },
        },
      },
    };
  },
};
</script>
