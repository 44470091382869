<template>
  <HAWPageContent :breadcrumb="false">
    <BaseRow>
      <BaseCol cols="12" md="9" lg="10">
        <BaseHeadline size="1" fake="1" noMargin>
          {{ $t("rp.reports.title") }}
        </BaseHeadline>
      </BaseCol>
      <BaseCol cols="12" md="3" lg="2">
        <DaysIntoPastSelect v-model="daysIntoPast" />
      </BaseCol>
    </BaseRow>

    <Reports :days-into-past="daysIntoPast" />
  </HAWPageContent>
</template>

<script>
import Reports from "@/modules/reports-rp/views/Reports/partials/Reports";
import DaysIntoPastSelect from "@/modules/reports-rp/views/Reports/partials/DaysIntoPastSelect";
import HAWPageContent from "@/modules/haw-components-hac/Base/HAWPageContent";

export default {
  components: { HAWPageContent, DaysIntoPastSelect, Reports },
  data() {
    return {
      daysIntoPast: 30,
    };
  },
};
</script>
